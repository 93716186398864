@use 'src/scss/vars';
@use 'src/scss/mixins';

.banner {
  display: block;
  width: 100%;
  min-height: 30px;

  color: var(--primary-text);
  font-size: 16px;
  line-height: 30px;
  text-align: center;

  backdrop-filter: blur(10px);

  @include mixins.b(vars.$mobile-md) {
    font-size: 13px;
  }

  a {
    cursor: pointer;
  }
}
