// ***** breakpoints *****
$laptop-xl: 1140;
$laptop: 1023;
$tablet: 768;
$mobile-md: 651;
$mobile-md-minus: 599;
$mobile-sm-plus: 560;
$mobile-sm-middle: 500;
$mobile-sm: 420;
// ***** colors *****
$primary-color: #EE804B;
$secondary-color: #271e34;
$transparent-black: rgba(0, 0, 0, 0.1);
$transparent-white: rgba(255, 255, 255, 0.1);
// ***** vars *****
:root {
    --scroll-size: 0px;
    --primary-color: #{$primary-color};
    --secondary-color: #{$secondary-color};
    --filter-inversion: invert(1);
    --accent-color: #9438a6;
    --transparent-line: #{$transparent-black};
    --primary-background: rgb(246, 246, 246);
    --form-background: rgb(255, 255, 255);
    --form-background-transparent: rgba(255, 255, 255, 0.9);
    --solid-background: rgb(255, 255, 255);
    --secondary-background: rgba(0, 0, 0, 0.035);
    --iframe-primary-background: rgb(244, 244, 244);
    --primary-text: rgb(0, 0, 0);
    --primary-text-invert: rgb(255, 255, 255);
    --secondary-text: rgba(0, 0, 0, 0.5);
    --tui-primary: #{$primary-color};
    --tui-primary-hover: #{darken($primary-color, 10%)};
    --tui-primary-active: #{darken($primary-color, 10%)};
    --tui-primary-text: var(--primary-text-invert);
    --tui-secondary: rgba(255, 255, 255, 1);
    --tui-secondary-hover: rgba(255, 255, 255, 0.5);
    --tui-secondary-active: rgba(255, 255, 255, 0.5);
    --tui-secondary-text: var(--primary-text);
    --tui-link: var(--primary-text);
    --tui-link-hover: var(--primary-text);
    --tui-text-02: #3b3a3a;
    --tui-text-03: var(--tui-text-02);
    --selection-color: rgb(243 249 243);
    --error-color: #e9590c;
    --warning-color: #f2994a;
}

// ***** typography *****
$sf-pro-font: SF-Pro,
sans-serif;
$roboto-font: Roboto,
sans-serif;
$montserrat-font: Montserrat,
sans-serif;
$red-hat-display: 'Kanit',
sans-serif;
// ***** colors *****
$rubic-base-11: #808080;
$rubic-primary: #4aa956;
$rubic-disable: #e1e1e1;
$rubic-warning: #dfcb5f;
$background-black: rgb(14, 17, 14);
$grey-background: #f4f4f4;
$buy-custom-token: #1968ff;
// ***** border radiuses *****
$border-radius-1: 14px;
$border-radius-2: 20px;
$border-radius-3: 100px;
// ***** filters *****
$backdrop-blur-1: blur(5px);
// ***** shadows *****
$shadow-block: inset 0 0 5px 1px rgba(0, 0, 0, 0.1);
$main-shadow: 0 3px 6px rgba(black, 0.16);
// ***** text colors *****
$rubic-text-color-main: #283125;
$rubic-text-disable: #909090;
$rubic-text-error: #d9573f;
$text-gray: #a8a8a8;
$text-gray-2: #878787;
// ***** font-sizes *****
$rubic-font-size-main: 14px;
$rubic-font-size-01: 16px;
$rubic-font-size-02: 18px;
// ***** iframe sizes *****
$iframe-horizontal-width: 1180px;
$iframe-horizontal-height: 180px;
$iframe-horizontal-input-width: 363px;
$iframe-vertical-width: 350px;
$iframe-vertical-height: 500px;
// ***** themes *****
$dark: 'dark';