// ****** mixins ******

@mixin font-face(
  $font-family,
  $file-path,
  $weight,
  $style: normal
) {
  @font-face {
    font-weight: $weight;
    font-family: $font-family;
    font-style: $style;
    font-display: block;

    src: url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.eot'), url('#{$file-path}.ttf') format('truetype');
  }
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;

    content: ' ';
  }
}

@mixin dark {
  :host-context(html.dark) & {
    @content;
  }
}

@mixin iframe-horizontal {
  :host-context(html.iframe-horizontal) & {
    @content;
  }
}

@mixin iframe-vertical {
  :host-context(html.iframe-vertical) & {
    @content;
  }
}

@mixin iframe {
  :host-context(html.iframe) & {
    @content;
  }
}

@mixin hide-unused-ui {
  :host-context(body.hide-unused-ui) & {
    @content;
  }
}

@mixin light-iframe {
  :host-context(html.iframe:not(.dark)) & {
    @content;
  }
}

@mixin iframe-horizontal-light {
  :host-context(html.iframe-horizontal:not(.dark)) & {
    @content;
  }
}

@mixin iframe-vertical-light {
  :host-context(html.iframe-vertical:not(.dark)) & {
    @content;
  }
}

@mixin iframe-card {
  :host-context(html.iframe) & {
    display: block;
    height: 27px;
    padding: 5px 10px;

    color: var(--tui-text-02);

    font-size: 12px;
    line-height: 17px;

    background-color: var(--primary-background);
    border-radius: 10px;
  }

  :host-context(html.iframe:not(.dark)) & {
    background-color: var(--iframe-primary-background);
  }
}

@mixin ios {

  @content;

}

@mixin bh($point) {
  @media (max-height: $point + 'px') {
    @content;
  }
}

@mixin b($point) {
  @if $point==desktop {
    @media (max-width: 1366px) and (min-width: 1024px) {
      @content;
    }
  } @else if $point==tablet {
    @media (max-width: 1000px) {
      @content;
    }
  } @else if $point==mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point==mobile3x {
    @media only screen and (-webkit-min-device-pixel-ratio: 3),
      only screen and (min-resolution: 3dppx),
      only screen and (min-resolution: 350dpi) {
      @content;
    }
  } @else {
    @media (max-width: $point + 'px') {
      @content;
    }
  }
}

@mixin bb($pointMax, $pointMin) {
  @media (max-width: $pointMax + 'px') and (min-width: $pointMin + 'px') {
    @content;
  }
}


@mixin before-img($display, $width, $height, $position) {
  @at-root #{&}:before {
    display: $display;

    width: $width;
    height: $height;

    content: '';

    @if $position==a {
      position: absolute;
    }

    @if $position==r {
      position: relative;
    }
    @content;
  }
}

@mixin after-img($display, $width, $height, $position) {
  @at-root #{&}:after {
    display: $display;

    width: $width;
    height: $height;

    content: '';

    @if $position==a {
      position: absolute;
    }

    @if $position==r {
      position: relative;
    }
    @content;
  }
}

@mixin font($fontSize, $lineHeight, $fontWeight, $color, $letterSpacing: normal) {
  color: $color;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin clearbtn() {
  padding: 0;

  font-size: inherit;
  line-height: inherit;

  background: none;
  border: 0;

  appearance: none;
}

@mixin assetImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
}
