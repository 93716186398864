@use 'src/scss/core' as *;

tui-dropdown-box {
  --tui-elevation-01: rgba(255, 255, 255, 0.03);
  --tui-radius-3: 19px;
  --tui-base-03: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--transparent-line) !important;
  box-shadow: none !important;
  backdrop-filter: blur(50px);
}

tui-dialog {
  overflow: hidden;

  @include b($mobile-sm-plus) {
    .header {
      flex: auto !important;
      overflow: auto !important;
    }
  }

  > .content {
    background-color: var(--form-background-transparent) !important;
    border: 1px solid var(--transparent-line);
  }

  &::after {
    box-shadow: unset !important;
  }

  &[data-size='page'] {
    > .content {
      height: 100%;
      padding: 2rem 1.5rem !important;
    }
  }

  &[data-size='l'] {
    width: 51em !important;
    margin-bottom: auto !important;

    border: 7px solid transparent !important;

    > .content {
      height: 100%;
      padding: 1.5em 1.5em 0 !important;

      @include b($mobile-sm-middle) {
        padding: 0 !important;
      }

      > section {
        height: 100%;
      }
    }
  }

  &[data-size='xl'] {
    width: 100% !important;
    max-width: 910px !important;
    
    > .content {
      padding: 32px !important;
    }
  }

  &[data-size='m'] {
    margin-bottom: auto !important;

    > .content {
      height: 100%;
      padding: 1.5em 1.5em 0 !important;

      > section {
        height: 100%;
      }
    }
  }

  &[data-size='s'] {
    .content {
      .heading {
        margin-right: 40px;

        font-size: 21px;
      }
    }
  }

  .wrapper {
    z-index: 1;
  }

  .header {
    background: var(--tui-base-01);
  }

  .close {
    position: absolute !important;
    top: 18px !important;
    right: 18px !important;

    color: #bfbfbf !important;

    background: transparent !important;
    transform: scale(1.2);

    &:hover {
      transform: scale(1.4);
    }
  }
}

::ng-deep tui-dialog {
  @include iframe-vertical {
    .close {
      top: 5px !important;
      right: 5px !important;
    }
  }
}

tui-dialog-host {
  overflow-y: auto;

  > div {
    backdrop-filter: blur(12px);
  }

  .overlay {
    overflow: inherit !important;

    background: rgb(0 0 0 / 0%) !important;
  }

  .dialog {
    overflow: inherit !important;
  }
}

tui-notification {
  .close {
    top: 10px !important;
  }
}

.t-text {
  font-size: $rubic-font-size-01;
}

tui-notification-alert {
  position: relative;
  top: var(--scroll-size);

  transition: 0.2s ease-in-out;
}

@include b($mobile-md-minus) {
  tui-dialog {
    height: 100%;
  }

  tui-hint-box {
    max-width: 160px !important;
  }

  .t-text {
    font-size: $rubic-font-size-main;
  }
}

::ng-deep tui-dialog .content {
  @include iframe {
    padding: 0 !important;
  }
}

::ng-deep tui-notifications-host {
  @include iframe {
    margin-top: 0 !important;

    > div.wrapper {
      padding-top: 15px;
    }
  }
}

::ng-deep tui-input-number input {
  border: none !important;
}

tui-value-decoration {
  position: absolute;
  top: 17px;
  right: 11px;
}

html.iframe-horizontal tui-dialog > div.content {
  padding: 0.5rem 2rem !important;
}

html.iframe-horizontal tui-dialog {
  &[data-size='s'] {
    width: 31rem;

    border: 0 !important;
  }
}

html.iframe-vertical tui-dialog > div.content {
  height: 100%;
  padding: 0 !important;

  border-radius: 20px;
}

html.iframe-vertical tui-dialog > div.content > h2 {
  padding: 2rem 0.5rem 0 1rem;
}

html.iframe-vertical tui-hint-box {
  max-width: 18rem !important;
}

html.iframe-horizontal tui-hint-box {
  max-width: 36rem !important;
}


tui-wrapper[data-appearance='textfield'] {
  --tui-base-03: rgba(255, 255, 255, 0.1);
  background: transparent !important;
}

tui-wrapper[data-appearance='secondary'] {
  &:hover,
  &:active {
    background-color: var(--tui-secondary-hover);
  }
}

tui-wrapper[data-appearance='outline'] {
  color: var(--primary-color);

  &::after {
    display: none;
  }
}

:not(app-swap-button-container) {
  app-rubic-button tui-wrapper[data-appearance='secondary'] {
    border: 1px solid var(--transparent-line);
  }
}

.light tui-toggle {
  tui-wrapper[data-appearance='secondary'] {
    &::after,
    .circle {
      border: 1px solid var(--transparent-line);
    }

    .circle {
      background: var(--transparent-line);
    }
  }
}

.light tui-wrapper[data-appearance='textfield'][data-appearance='textfield']::after {
  border: 1px solid var(--transparent-line);
}
