@use 'src/scss/mixins' as *;

// *****  icons styles *****
.icon {
  &::before {
    display: flex;
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-position: center;
    background-size: initial;
    border-radius: 5px;

    content: '';
  }

  &__checked {
    display: flex;
    align-items: center;
    justify-content: center;

    @include before-img(block, 20px, 20px, r) {
      min-width: 20px !important;
      min-height: 20px !important;
      margin-right: 25px;

      background-image: url('/assets/images/icons/icon-checked.svg');
      background-size: contain;
    }
  }
}
